import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { getALlCategory } from 'app/services/shop/shopAPI';
import { Avatar } from '@mui/material';
import { getName } from 'app/services/UtilServices';

interface CategoryTabProps {
    selectedCategoryId: string;
    onCategoryChange: (categoryId: string) => void;
    products: Product[]; // Add products as a prop
}

interface Category {
    id: string;
    title: string;
    imageUrl: string
    // You can add other category-related properties here
}

interface Product {
    categoryIds: Array<string>;
    // Add other product properties as needed
}


const CategoryTab: React.FC<CategoryTabProps> = ({
    selectedCategoryId,
    onCategoryChange,
    products
}) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchALlCategories()
    }, [onCategoryChange]);

    const fetchALlCategories = async () => {
        try {
            const response = await getALlCategory();
            const shopCategoryIds = products.flatMap((product) => product.categoryIds);
            if (shopCategoryIds.length > 0 && response.data.length > 0) {
                const availableCategories = response.data.filter((category: any) =>
                    shopCategoryIds.includes(category.id)
                );
                setCategories(availableCategories);
            }
        } catch (error) {
            console.error('Error fetching categories', error);
        }
    };

    const handleChange = (event: React.SyntheticEvent, newCategoryId: string) => {
        onCategoryChange(newCategoryId);
    };


    return (
        <>
            <h2>{getName()}, what makes you happy ?</h2>
            <Box sx={{ flexGrow: 1, width: '100%', bgcolor: 'antiquewhite' }} display="flex" justifyContent="center" width="100%">

                <Tabs
                    value={selectedCategoryId}
                    onChange={handleChange}
                    aria-label="category tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {categories.map((category: Category) => (
                        <Tab
                            key={category.id}
                            value={category.id}
                            label={category.title}
                            icon={<Avatar sx={{ width: 66, height: 66 }}
                                alt={category.title} src={category.imageUrl} />}
                        />
                    ))}
                </Tabs>
            </Box>
        </>
    );
};

export default CategoryTab;
