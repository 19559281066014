import React from 'react';
import { styled } from '@mui/system';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Grid } from '@mui/material';


const MainContainer = styled('div')({
    height: '21rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const ProfileCard = styled('div')({
    position: 'relative',
    width: '220px',
    height: '220px',
    background: '#fff',
    padding: '30px',
    borderRadius: '50%',
    boxShadow: '0 0 22px #3336',
    transition: '.6s',
    margin: '0 25px',
    '&:hover': {
        borderRadius: '10px',
        height: '260px',
    },
});

const ImageContainer = styled('div')({
    position: 'relative',
    width: '100%',
    height: '100%',
    transition: '.6s',
    zIndex: 99,
    '&:hover': {
        transform: 'translateY(-60px)',
    },
});

const ProfileImage = styled('img')({
    width: '100%',
    borderRadius: '50%',
    boxShadow: '0 0 22px #3336',
    transition: '.6s',
    '&:hover': {
        borderRadius: '10px',
    },
});

const Caption = styled('div')({
    textAlign: 'center',
    transform: 'translateY(-80px)',

});

const Name = styled('h3')({
    fontSize: '21px',
    fontFamily: 'sans-serif',

});

const SocialLinks = styled('div')({
    marginTop: '5px',
    color: 'black',
});

const SocialLink = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
        marginRight: '8px',
    },
});

const ProfileList = () => {
    const profiles = [
        {
            name: 'Jamaluddin Mondal',
            imageSrc: "https://khabarbolo.s3.ap-south-1.amazonaws.com/misc/WhatsApp+Image+2023-10-16+at+9.41.56+AM.jpeg",
            socialLinks: [
                { url: 'https://facebook.com', icon: <FacebookIcon />, label: 'Facebook' },
                { url: 'https://twitter.com', icon: <TwitterIcon />, label: 'Twitter' },
            ],
        },
        {
            name: 'Ibrahim SK',
            imageSrc: "https://khabarbolo.s3.ap-south-1.amazonaws.com/misc/ibrahim.png",
            socialLinks: [
                { url: 'https://facebook.com', icon: <FacebookIcon />, label: 'Facebook' },
                { url: 'https://twitter.com', icon: <TwitterIcon />, label: 'Twitter' },
            ],
        },
        {
            name: 'Saifujjaman Mondal',
            imageSrc: "https://khabarbolo.s3.ap-south-1.amazonaws.com/misc/jaman.jpg",
            socialLinks: [
                { url: 'https://facebook.com', icon: <FacebookIcon />, label: 'Facebook' },
                { url: 'https://twitter.com', icon: <TwitterIcon />, label: 'Twitter' },
            ],
        },
        // Add more profiles as needed
    ];

    return (
        <React.Fragment>
            <Grid container spacing={2} style={{ justifyContent: "center" }}>

                {profiles.map((profile, index) => (
                    <MainContainer key={index} >
                        <Grid item xs={12} sm={12} md={4} style={{ justifyContent: "center", margin: "0px" }}>
                            <ProfileCard>
                                <ImageContainer>
                                    <ProfileImage src={profile.imageSrc} alt={profile.name} />
                                </ImageContainer>
                                <Caption>
                                    <Name>{profile.name}</Name>
                                    <SocialLinks style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                                        <SocialLink>
                                            <FacebookIcon />
                                            <a href={profile.socialLinks[0].url} target="_blank" rel="noopener noreferrer"> </a>
                                        </SocialLink>
                                        <SocialLink>
                                            <TwitterIcon />
                                            <a href={profile.socialLinks[1].url} target="_blank" rel="noopener noreferrer"> </a>
                                        </SocialLink>
                                    </SocialLinks>
                                </Caption>
                            </ProfileCard>
                        </Grid>
                    </MainContainer>

                ))}
            </Grid>

        </React.Fragment>
    );
};

export default ProfileList;
