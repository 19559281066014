import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { onMessageListener, requestForToken } from "common/firebase";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import { green } from "@mui/material/colors";

import Home from "pages/HomePage";
import Login from "pages/LoginPage";
import Register from "pages/RegisterPage";
import PrivacyPolicy from "pages/PrivacyPolicyPage";
import Faq from "pages/FaqPage";
import CartPage from "pages/CartPage";
import FooterSection from "layout/Footer/Footer";
import Navbar from "layout/Navbar/Navbar";
import Profile from "pages/ProfilePage";
import ForgotPassword from "pages/ForgotPassword";
import ShopDetails from "components/ShopHome/ShopHome";
import PageNotFound from "components/Miscellaneous/PageNotFound";
import AboutUsPage from "pages/AboutUsPage";
import { AuthenticatedRoute } from "common/AuthenticateRoute";
import { storeFCM } from "app/services/customer/customerAPI";
import { findUserId } from "app/services/UtilServices";
import { toast } from "react-toastify";


const outerTheme = createTheme({
  palette: {
    primary: {
      main: orange[500],
    },
    secondary: {
      main: green[500],
      light: "#F5EBFF",
    },
  },
});

const App = () => {

  const [fcm, setFcm] = React.useState("");

  async function requestPermission() {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await requestForToken();
        const userId = findUserId();
        if (token && userId) {
          storeFCM(userId, token)
        }
      } else if (permission === "denied") {
        toast.info("Please allow notifcation manually");
      }
    } catch (error) {
      console.error("Error occurred while requesting notification permission:", error);
    }
  }
  React.useEffect(() => {
    requestPermission();
  }, [fcm]);


  onMessageListener().then((payload: any) => {
    console.log("payload", payload);
  }).catch(err => console.log('failed: ', err));



  return (
    <ThemeProvider theme={outerTheme}>
      <div>
        <Router>
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <AuthenticatedRoute exact path="/shop/:id" component={ShopDetails} />
            <Route exact path="/cart" component={CartPage} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/reset" component={ForgotPassword} />
            <Route exact path="/terms-condition" component={PrivacyPolicy} />
            <Route exact path="/Profile" component={Profile} />
            <Route exact path="/f-a-q" component={Faq} />
            <Route exact path="/aboutus" component={AboutUsPage} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Router>
        <FooterSection />
      </div>
    </ThemeProvider>
  );
};

export default App;
