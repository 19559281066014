import React from 'react';

interface Iveg {
  type: boolean
}
const VegNonVeg = (props: Iveg) => {
  const { type } = props
  return (
    <>
      {type ? <img height="20px" width="20px" src='/static/veg.png' alt="veg icon" style={{ position: "absolute" }} /> : <img height="20px" width="20px" src='/static/nonveg.png' alt="nonveg icon" style={{ position: "absolute" }} />}

    </>
  )
}

export default VegNonVeg;