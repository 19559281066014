import React, { useEffect, useState } from "react";
import {
    Table,
    Tabs,
    Tab,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Container,
    Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { allOrders } from "app/services/order/orderAPI";
import { findUserId } from "app/services/UtilServices";
import { IOrder } from "app/order";
import moment from "moment";
import OrderDetailsPage from "./OrderDetailsPage";
import { ORDER_STATUS } from "common/constant";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#f58320",
        color: "white",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#fff2e0",
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));



const OrderPage = () => {
    const [value, setValue] = useState("one");
    const [deliveredOrders, setDeliveredOrders] = useState([]);
    const [notDeliveredOrders, setNotDeliveredOrders] = useState([]);
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showDetails, setShowDetails] = useState(false)
    const [open, setOpen] = React.useState(false);

    const handleChange = (event: any, newValue: string) => {
        setValue(newValue);
        filterOrders(orders, newValue);
    };

    const filterOrders = (ordersArray: any, type: string) => {
        if (type === "one") {
            const currentOrders = ordersArray.filter((order: any) => order.status !== 2);
            setDeliveredOrders(currentOrders);
            setNotDeliveredOrders(currentOrders);
        } else if (type === "two") {
            const pastDeliveredOrders = ordersArray.filter((order: any) => order.status === 2);
            const pastNotDeliveredOrders = ordersArray.filter((order: any) => order.status !== 2);
            setDeliveredOrders(pastDeliveredOrders);
            setNotDeliveredOrders(pastNotDeliveredOrders);
        }
    };

    useEffect(() => {
        const userId = findUserId();
        fetchAllOrders(userId);
    }, []);


    const fetchAllOrders = async (userId: string) => {
        try {
            const orderResp = await allOrders(userId);
            if (orderResp && orderResp.data) {
                const allOrders = orderResp?.data;
                setOrders(allOrders);
                filterOrders(allOrders, value);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const selectedOrderInfo = (items: any) => {
        setSelectedOrder(items);
        setOpen(true)
    };


    return (
        <>
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="md">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="primary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="one" label="Current Order" />
                        <Tab value="two" label="Past Order (last 3 days)" />
                    </Tabs>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>SL No</TableCell>
                                    <TableCell>Order on</TableCell>
                                    <TableCell>Seller Name</TableCell>
                                    <TableCell>Order Id</TableCell>
                                    <TableCell align="right"> Price</TableCell>
                                    <TableCell align="right">Order Status</TableCell>
                                    <TableCell align="right">View </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deliveredOrders.map((item: IOrder, i: any) => (
                                    <StyledTableRow
                                        key={i}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <StyledTableCell component="th" scope="item">
                                            {i + 1}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="item">
                                            {moment(item?.createdAt).format("LLL")}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {item?.shop?.name}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {item?.orderNo}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            ₹{item?.totalPrice}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            {ORDER_STATUS[`${item?.status}`]}


                                        </StyledTableCell>


                                        <StyledTableCell
                                            align="right"
                                            onClick={() => {
                                                selectedOrderInfo(item);
                                            }}
                                        >
                                            <Tooltip title="View Details">

                                                <OpenInNewIcon style={{ color: "#f39101", height: "30px", width: "30px" }} />
                                            </Tooltip>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>

                <Container>
                    <OrderDetailsPage items={selectedOrder} open={open} setOpen={setOpen} />
                </Container>
            </Box>
        </>
    );
};

export default OrderPage;