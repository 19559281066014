import React from 'react';
import Carousel from "react-material-ui-carousel";
import { Container } from '@mui/material';

const BannerProduct = (props: any) => {
  const { banners } = props

  return (
    <Container style={{ marginLeft: "auto", paddingTop: "1rem", marginRight: "auto", justifyContent: "center" }} >
      <Carousel animation="slide" >
        {banners.map((banner: any, i: number) => (
          <div key={banner.id}>
            <img
              src={banner.image}
              alt={banner.title}
              style={{
                width: "90%",
                height: "auto",
                objectFit: "contain",
                textAlign: "center",
              }}
            />
            <p style={{ textAlign: "center" }}>{banner.title}</p>
          </div>

        ))}
      </Carousel>
    </Container>

  );
};

export default BannerProduct;

