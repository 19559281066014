import React from 'react'
import { Button } from "@mui/material";
import Grid from '@mui/material/Grid';

const PageNotFound = () => {

    return (
        <>
            <Grid container style={{ marginTop: "20rem", justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "20rem" }}>
                <Grid item xs={12} sm={12} md={12}>
                    <div >
                        <h1>404</h1>
                        <h4>PAGE NOT FOUND</h4>
                        <Button variant="contained" style={{ color: "white" }} href='/'>Back To Home</Button>
                    </div>

                </Grid>
            </Grid>
        </>
    )
}

export default PageNotFound