import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ShopList from '../components/ShopList/ShopList'


const ShopListingMainPage = () => {


    return (
        <React.Fragment>
            <CssBaseline />
            <Container

            >
                <Typography
                    component="h6"
                    variant="h4"
                    align="center"
                    color="text.primary"
                    gutterBottom
                >
                    Discover the best food items near to you!
                </Typography>
                <br></br>


                <ShopList />
            </Container>



        </React.Fragment >
    );
};

export default ShopListingMainPage;
