import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from "react-toastify";

interface CartItem {
    product: Product;
    quantity: number;
    shopId: string;

}

interface Product {
    id: string;
    name: string;
    sellingPrice: number
}

interface CartState {
    items: CartItem[];
    totalAmount: number; // Add this field for the total amount
}

const initialState: CartState = {
    items: localStorage.getItem('cartItems')
        ? JSON.parse(localStorage.getItem('cartItems'))
        : [],
    totalAmount: 0
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem: (state, action: PayloadAction<{ product: Product; shopId: string }>) => {
            const { product, shopId } = action.payload;
            const existingItem = state.items.find(
                (item) => item.product.id === product.id && item.shopId === shopId
            );

            if (existingItem) {
                existingItem.quantity += 1;
            } else {
                state.items.push({ product, quantity: 1, shopId });
            }

            state.totalAmount = state.items.reduce(
                (total, item) => total + item.product.sellingPrice * item.quantity,
                0
            );

            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        updateQuantity: (
            state,
            action: PayloadAction<{ id: string; quantity: number }>
        ) => {
            const { id, quantity } = action.payload;
            const existingItemIndex = state.items.findIndex(
                (item) => item.product.id === id
            );

            if (existingItemIndex !== -1) {
                if (quantity > 0) {
                    state.items[existingItemIndex].quantity = quantity;
                } else {
                    state.items.splice(existingItemIndex, 1);
                }
                if (quantity > 7) {
                    state.items[existingItemIndex].quantity = 7;
                    toast.error(`You can't add this item more than 7 quantity`)

                }

            }
            state.totalAmount = state.items.reduce(
                (total, item) => total + item.product.sellingPrice * item.quantity,
                0
            );
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        removeItem: (state, action: PayloadAction<{ productId: string }>) => {
            const { id } = action.payload;
            state.items = state.items.filter(
                (item) => item.product.id !== id
            );
            state.totalAmount = state.items.reduce(
                (total, item) => total + item.product.sellingPrice * item.quantity,
                0
            );
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        loadCart: (state) => {
            const storedCart = localStorage.getItem('cartItems');
            if (storedCart) {
                state.items = JSON.parse(storedCart);
            }
            state.totalAmount = state.items.reduce(
                (total, item) => total + item.product.sellingPrice * item.quantity,
                0
            );
        },
        calculateTotalAmount: (state) => {
            const totalAmount = state.items.reduce((total, item) => total + item.product.sellingPrice * item.quantity, 0);
            state.totalAmount = totalAmount;
        },
    },
});

export const { addItem, updateQuantity, removeItem, loadCart, calculateTotalAmount } = cartSlice.actions;

export default cartSlice.reducer;
