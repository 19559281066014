import { useState } from "react";

import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Button, Grid } from "@mui/material";
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify";
import { login } from "../../store/authSlice";
import { setToken } from "app/services/UtilServices";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import "../../style/login.css";
import { socialLogin } from "app/services/customer/customerAPI";

interface IGooglUser {
    data: {
        email: string
        family_name: string
        given_name: string
        sub: string
    }
}

const GoogleAuth = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state: any) => state.auth.isLogedIn);


    const [googleUser, setGoogleUser] = useState({
        email: "",
        family_name: "",
        given_name: "",
        sub: ""
    })

    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            let userInfo: IGooglUser = await axios
                .get("https://www.googleapis.com/oauth2/v3/userinfo", {
                    headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
                })
            //.then((res) => res.data);
            const data = {
                email: userInfo.data.email,
                socialId: userInfo.data.sub,
                regForm: "google",
                firstName: userInfo.data.given_name,
                lastName: userInfo.data.family_name

            }
            const result = await socialLogin(data)
            if (result.success && result.data.token) {

                localStorage.clear();
                toast.success("Google login successful!");
                setToken(result.data.token)
                dispatch(login());
                history.push("/");
                window.location.reload();
            } else {
                localStorage.clear();
                toast.error("Login unsuccessful!");
            }
        },
    });
    return (
        <>
            <Grid container maxWidth="xs" >
                <Grid item xs={12} md={12} sx={{ marginTop: "1rem", paddingBottom: "1rem" }}>

                    <Button type="submit"
                        fullWidth
                        variant="outlined"
                        color="primary"
                        id="btn" onClick={() => googleLogin()}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        <img src="/blog/Google@3x.png" alt="google-logo" id="img" />
                        <span id="text">Login In with Google</span>
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
export default GoogleAuth;
