// A mock function to mimic making an async request for data
import { handleErrorResp, httpClient } from "../UtilServices";

export async function searchShops() {
  try {
    return await httpClient<any>(`shops`, 'POST');
  } catch (err) {
    return handleErrorResp(err);
  }
}


export async function shopsProduct(shopId: string) {
  try {
    return await httpClient<any>(`shops/${shopId}/products`, 'GET');
  } catch (err) {
    return handleErrorResp(err);
  }
}
export async function getALlCategory() {
  try {
    return await httpClient<any>(`getall/categories`, 'GET');
  } catch (err) {
    return handleErrorResp(err);
  }
}
