import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Slide, Card, Typography, Container, Table, TableBody, Dialog, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import { TransitionProps } from '@mui/material/transitions';
import { ORDER_STATUS } from "common/constant";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const OrderDetailsPage = (props: any) => {

    const { items, open, setOpen, } = props;

    const handleClose = () => {
        setOpen(false);
    };
    const address = items?.shippingAddress


    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Container
                    component="main"
                    sx={{ mt: 8, mb: 2 }}
                    maxWidth="md"
                >
                    <Grid style={{ display: "flex", justifyContent: "end" }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            style={{ backgroundColor: "#f39101", color: "white" }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Card>
                        <div style={{ justifyContent: "center", margin: "10px", padding: "10px" }}>
                            Order status : <span style={{ color: "#f39101" }}>{ORDER_STATUS[`${items?.status}`]}</span>
                        </div>
                    </Card>

                    <Grid container style={{ textAlign: "left", margin: "auto", alignContent: "center", justifyContent: "center", width: "100%" }}>
                        <Grid item xs={12} md={6} sm={12}>
                            <Card style={{ paddingLeft: "2rem", paddingBottom: "1rem" }} >
                                <h3>Order Summary</h3>
                                <Typography>Order Id: {items?.orderNo}</Typography>
                                <Typography>Order Time: {moment(items?.orderTime).format("LLLL")}</Typography>
                                <Typography>Seller : {items?.shop?.name}</Typography>
                                <Typography>Address: {items?.shop?.address}</Typography>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <Card style={{ paddingLeft: "2rem", paddingBottom: "1rem" }}>
                                <h3>Shipping Address</h3>
                                <Typography>{address?.name}</Typography>
                                <Typography>{address?.phone}</Typography>
                                <Typography>{address?.landMark}</Typography>
                                <Typography>{address?.village}, {address?.area}</Typography>
                            </Card>
                        </Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>SL No</TableCell>
                                            <TableCell>Item</TableCell>
                                            <TableCell>No of Item</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items?.products.map((product: any, i: number) => (
                                            <TableRow
                                                key={i}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {product?.title}
                                                </TableCell>
                                                <TableCell>{product?.quantity}</TableCell>
                                                <TableCell>{product?.unitLabel}</TableCell>
                                                <TableCell>&#8377;{product?.totalPrice}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </>
    );
};

export default OrderDetailsPage;
