import * as React from "react";
import { useSelector } from "react-redux";
import "./Navbar.css";
import { Badge, Typography, AppBar, Box, Toolbar, Menu, Link, Tooltip, MenuItem, Container, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import { getToken } from "app/services/UtilServices";
import TranslateComponent from "components/Miscellaneous/TranslateComponent";

const Navbar = () => {

  //@ts-ignore
  const cartItems = useSelector((state) => state.cart.items);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.clear()
    toast.success("Log out successfully")
  }


  //@ts-ignore
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);



  return (
    <AppBar position="static" style={{ height: "0px", paddingTop: "0px" }}>
      <Container maxWidth="md">
        <ToastContainer autoClose={2000} />

        <Toolbar disableGutters  >
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu} >
                <Link style={{ textDecoration: "none" }}
                  target="_blank" href="/aboutus">About Us
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu} >
                <Typography textAlign="center">
                  <Link style={{ textDecoration: "none" }}
                    target="_blank" href="https://www.facebook.com/khabarBolo">Contact Us
                  </Link>
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu} >
                {!getToken() ?
                  <Button href="/login"   >
                    login
                  </Button> :

                  <Button style={{ marginLeft: "-5px" }} onClick={handleLogout}>Log out</Button>
                }
              </MenuItem>
            </Menu>
          </Box>
          <div style={{ marginTop: "1rem" }}  >
            <Tooltip title="Hello!, welcome!">
              <a href="/">
                <img
                  src="/static/onlyLogo.png"
                  data-rimg-scale="1"
                  height={100}
                  width={120}
                  alt="logo"

                />
              </a>
            </Tooltip>
          </div>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <TranslateComponent />
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: "contents" } }}>

              <Tooltip title="See more food items">
                <Button href="/" startIcon={<LocalDiningIcon />}>Explore</Button>
              </Tooltip>
            </Box>
            <Tooltip title="See your cart items">
              <Button href="/cart" >
                Cart
                <Badge badgeContent={totalItems} color="primary">
                  <AddShoppingCartIcon color="primary" />
                </Badge>
              </Button>
            </Tooltip>
            {!getToken() ?
              <Button variant="outlined" href="/login"   >
                login
              </Button> :
              null}
            {getToken() &&
              <Tooltip title="See Profile">

                <Button variant="outlined" href="/profile"   >
                  profile
                </Button>
              </Tooltip>
            }
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
