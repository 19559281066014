// A mock function to mimic making an async request for data
import { IOrder } from "app/order";
import { handleErrorResp, httpClient } from "../UtilServices";

export async function createOrder(order: IOrder) {
    try {
        return await httpClient<any>(`customer/order/create`, 'PUT', order);
    } catch (err) {
        return handleErrorResp(err);
    }
}

export async function allOrders(userId: string) {
    try {
        return await httpClient<any>(`user/orders`, 'POST', { userId: userId });
    } catch (err) {
        return handleErrorResp(err);
    }
}

export async function addOrder(order: IOrder) {
    try {
        return await httpClient<any>(`orders`, 'PUT', order);
    } catch (err) {
        return handleErrorResp(err);
    }
}
export async function confirmOrder(order: any) {
    try {
        return await httpClient<any>(`confirm-order`, 'POST', order);
    } catch (err) {
        return handleErrorResp(err);
    }
}

