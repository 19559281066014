import React from 'react';

const TranslateComponent = () => {

  return (
    <div id="google_translate_element"></div>
  );

}

export default TranslateComponent;