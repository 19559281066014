import React, { useEffect, useState } from "react";
import HeroSection from "../components/HeroSection/HeroSection";
import Features from "../components/Blog/Blog";
import ShopListingMainPage from "./ShopListing";
import "./home.css";

const Home = () => {
  const [isLateNight, setIsLateNight] = useState(false);

  useEffect(() => {
    const currentHour = new Date().getHours();
    setIsLateNight(currentHour < 10 || currentHour >= 21);
  }, []);

  return (
    <div className={`home-container ${isLateNight ? 'black-and-white' : ''}`}>
      <HeroSection />
      <main>
        {isLateNight &&
          <div className="overlay">
            <ShopListingMainPage />
          </div>
        }
        {!isLateNight && <ShopListingMainPage />}
        <Features />
      </main>
    </div>
  );
};

export default Home;
