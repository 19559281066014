import React, { useState, useEffect } from "react";
import { Card, CardMedia, Container, Grid, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";

import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import { searchShops } from "app/services/shop/shopAPI";
import Spinner from "components/Miscellaneous/Spinner";


const ShopList = () => {
    let [loading, setLoading] = useState(true);
    const [shops, setShops] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("useEffect triggered"); // Add this line
        if (shops.length === 0) {
            console.log("API call made"); // Add this line
            fetchProducts();
        }
    }, []);
    async function fetchProducts() {
        try {
            const shopsData = await searchShops();
            setShops(shopsData.data);
            setLoading(false);
        } catch (error) {
            // Handle error here
            console.error("Error fetching shops:", error);
            setLoading(false);
        }
    }

    return (
        <>
            <Container maxWidth="lg" component="main">
                <Typography
                    variant="h6"
                    align="left"
                    color="text.secondary"
                    component="p"
                >
                    Indulge in placing your order and lean back in comfort. Entrust us,
                    and experience the delight of your meal delivered promptly to your
                    doorstep.
                </Typography>
                <br></br>
                <Typography style={{ textAlign: "left", fontSize: "x-large" }}>
                    Top restaurants at Hariharpara
                </Typography>
                {loading ? (
                    <Spinner />
                ) : (
                    <div>
                        {shops.length > 0 ? (
                            <Grid container spacing={5} alignItems="flex-end">
                                {shops.map((item: any) => (
                                    <Grid item xs={12} md={4} key={item.shopId}>

                                        <div className="cards-container">
                                            <a href={`/shop/${item.shopId}`}>
                                                <div className="card">
                                                    <div className="card-media">
                                                        <Card sx={{ maxWidth: 390 }}>
                                                            <CardMedia
                                                                sx={{ height: 220 }}
                                                                image={item?.photoUrl}
                                                                title={item.name}
                                                            />
                                                        </Card>
                                                        <div className="discount">
                                                            12% OFF UPTO &#x20b9;100
                                                        </div>
                                                        <div className="pro-discount">Free Delivery</div>
                                                        <div className="delivery-time">30 mins</div>
                                                    </div>
                                                    <div className="card-description">
                                                        <div className="about-place">
                                                            <div className="place">
                                                                <h3 className="place-name">{item.name}</h3>
                                                                <p className="place-speciality">
                                                                    {item.address}
                                                                </p>
                                                            </div>
                                                            <div className="place-review">
                                                                <Tooltip title="This is primary rating by KhabarBolo">
                                                                    <p className="rating">4.6 &#x2605;</p>
                                                                </Tooltip>
                                                                <Tooltip title="Average spend amount is 150">
                                                                    <p className="per-person">
                                                                        {" "}
                                                                        Avg &#x20b9;150 for one
                                                                    </p>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Typography
                                color="primary"
                                style={{
                                    textAlign: "center",
                                    paddingLeft: "2rem",
                                    fontSize: "x-large",
                                }}
                            >
                                No restaurants or shops are available right now!
                            </Typography>
                        )}
                    </div>
                )}
            </Container>
        </>
    );
};

export default ShopList;
