import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import RestaurantFeatured from '../ShopView/ShopDetails';
import FeaturedPost from '../ShopView/FeatureItem';
import Product from '../Product/Product';
import CategoryTab from '../../layout/Tabs/CategoryTab';
import { shopsProduct } from 'app/services/shop/shopAPI';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getToken } from 'app/services/UtilServices';
import Spinner from 'components/Miscellaneous/Spinner';
import { useHistory } from "react-router-dom"

interface IProduct {
    id: string;
    title: string;
    description: string;
    isVeg: boolean;
    quantity: number;
    unitLabel: string;
    sellingPrice: number;
    discount: string;
    imageUrl: string;
    preparingTime: string;
    categoryIds: Array<string>;
    IsVeg: boolean;
}

interface IShopInfo {
    _id: string;
    name: string;
    ownerName: string;
    ownerMobile: string;
    ownerEmail: string;
    ownerContact: string;
    address: string;
    photoUrl: string;
    shopId: string;
    createdAt: string;
    updatedAt: string;
    id: string;
}

const ShopDetails: React.FC = () => {

    const [products, setProducts] = useState<IProduct[]>([]);
    const [featureItems, setFeatureItems] = useState<IProduct[]>([]);
    const [shopInfo, setShopInfo] = useState<IShopInfo>({});
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>('');
    const [loading, setLoading] = React.useState(false);
    const { id }: any = useParams();
    let history = useHistory();

    useEffect(() => {

        if (id) {
            const token = getToken()
            if (!token) {
                history.push("/login")
                return
            } else {
                fetchShopsAndProducts();
            }
        } else {
            // Handle case where id is missing
            toast.error('Login to continue');
        }
    }, [id]);


    const fetchShopsAndProducts = async () => {
        try {
            setLoading(true);
            const response = await shopsProduct(id); // Assuming shopsProduct is your API function
            if (response?.data?.shop) {
                setShopInfo(response.data.shop);
                setProducts(response.data.products || []);
                setLoading(false);

                if (response.data.products && response.data.products.length > 0) {
                    const featureItems = getRandomItems(response.data.products);
                    setFeatureItems(featureItems);
                    setSelectedCategoryId(featureItems[0]?.categoryIds[0] || '');
                }
            } else {
                // Handle case where shop data is not available in the response
                toast.error('Shop data not found in the response');
            }
        } catch (error) {
            //@ts-ignore
            if (error.response && error.response.status === 401) {
                // Handle unauthorized access (invalid/expired token) by redirecting to login
                history.push("/login");
            } else {
                console.error('Error fetching shop and products:', error);
                // Handle other errors here, e.g., show a toast message
                toast.error('Error fetching shop and products');
            }

        };

    }
    const getRandomItems = (products: IProduct[]) => {
        const randomItems: IProduct[] = [];
        while (randomItems.length < 2) {
            const randomIndex = Math.floor(Math.random() * products.length);
            const randomItem = products[randomIndex];
            if (!randomItems.includes(randomItem)) {
                randomItems.push(randomItem);
            }
        }
        return randomItems;
    };

    const handleCategoryChange = (categoryId: string) => {
        setSelectedCategoryId(categoryId);
    };

    const filteredProducts = products?.filter((product) =>
        product.categoryIds.includes(selectedCategoryId)
    );


    return (
        <>
            <CssBaseline />
            <Container maxWidth="lg" style={{ marginTop: '10rem' }}>
                {loading ?
                    <Spinner />
                    :
                    <>

                        <RestaurantFeatured shopInfo={shopInfo} />
                        <Grid container spacing={4}>
                            {featureItems.map((product: IProduct) => (
                                <FeaturedPost key={product.id} product={product} />
                            ))}
                        </Grid>
                        <br></br>
                        <CategoryTab
                            selectedCategoryId={selectedCategoryId}
                            onCategoryChange={handleCategoryChange}
                            products={products}
                        />
                        <br></br>
                    </>}
            </Container>
            <Product title="Recommended | Best Seller" products={filteredProducts} shopId={id} />
            <br></br>

        </>
    );
};

export default ShopDetails;
