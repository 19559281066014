import React, { useRef } from 'react';
import "./styles.css";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typewriter from 'typewriter-effect';
import BannerProduct from "../Carousel/CarouselBanner";
import { BiDownArrowAlt } from 'react-icons/bi';
import NotificationArea from './NotificationArea';
import { getBanners } from 'app/services/home/GeneralAPI';

const HeroSection = () => {
  const [banners, setBanners] = React.useState([]);
  const [message, setMessage] = React.useState({});

  React.useEffect(() => {
    fetchAllBanners();
  }, []);

  const fetchAllBanners = async () => {
    try {
      const response = await getBanners();
      if (response && response.data.length > 0) {
        const [head, ...rest] = response.data
        setBanners(rest)
        setMessage(head)
      }
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };



  return (
    <>
      <Container >
        <Grid container spacing={2} style={{ marginTop: "10rem" }}>
          <Grid item xs={12} md={6} style={{ paddingTop: "9rem" }}>
            <div className="content-main notranslate" >
              <Typewriter
                options={{
                  strings: [
                    'Hungry?',
                    'Cooking gone wrong?',
                    'Unexpected guests?',
                    'Wish it, We\'ll Fetch it!',
                    'Craving a delicious meal?',
                    'Is it time to fill your belly?',
                    'Wish it, We\'ll Fetch it!',
                    'Looking for a snack or a meal?',
                    'Wish it, We\'ll Fetch it!'
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />

              <p>Order food from favourite restaurants near you. <span style={{ color: "#f58320" }}>@Hariharpara</span></p>
              <button style={{ backgroundColor: "#f58320" }}>
                Choose restaurants <BiDownArrowAlt />
              </button>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <BannerProduct banners={banners} />
          </Grid>
        </Grid>
      </Container>

      <NotificationArea message={message} />

    </>

  )
}

export default HeroSection;
