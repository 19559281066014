import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
export const VAPIDKEY = 'BPSKeuu5ixb6dMzJ3QcWHN2ydZFD_hTfPgk529uKsXa0lCCIHGbRXc5WfCv3GrwVD5w1xj9qo-z3TAJOg7B4pTA';

export const firebaseConfig = {
    apiKey: "AIzaSyBP2BBzQK1ZfrQ8sQdalOTkmGG66kGsOCo",
    authDomain: "khabarbolo-77d4b.firebaseapp.com",
    databaseURL: "https://khabarbolo-77d4b-default-rtdb.firebaseio.com",
    projectId: "khabarbolo-77d4b",
    storageBucket: "khabarbolo-77d4b.appspot.com",
    messagingSenderId: "792870786224",
    appId: "1:792870786224:web:90a65317c6a55f86fc5775",
    measurementId: "G-3N4BXQPEWQ"
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestForToken = async () => {
    try {
        const messaging = getMessaging();
        const clintToken = await getToken(messaging, {
            vapidKey: VAPIDKEY
        });
        if (clintToken) {
            console.log('current token for client: ', clintToken);
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
        }
        return clintToken;
    } catch (error) {
        console.log('An error occurred while retrieving token. ', error);
    }
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(getMessaging(), (payload) => {
            resolve(payload);
        });
    });


export default initializeApp(firebaseConfig);


