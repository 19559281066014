import React from 'react'
import ClockLoader from "react-spinners/ClockLoader";


const Spinner = () => {

    return (
        <>
            <div style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>
                <ClockLoader
                    size={150}
                    color="#ff9e11" />

            </div>
        </>
    )
}

export default Spinner