// A mock function to mimic making an async request for data
import { handleErrorResp, httpClient } from "../UtilServices";

export async function getBanners() {
    try {
        return await httpClient<any>(`banners`, 'GET');
    } catch (err) {
        return handleErrorResp(err);
    }
}

