// A mock function to mimic making an async request for data
import { IAddress } from "../../address";
import { handleErrorResp, httpClient } from "../UtilServices";



export async function findAddress(userId: string) {
    try {
        return await httpClient<any>(`address/${userId}`, 'GET');
    } catch (err) {
        return handleErrorResp(err);
    }
}

export async function addAaddress(data: IAddress) {
    try {
        return await httpClient<any>(`address/add`, 'PUT', data);
    } catch (err) {
        return handleErrorResp(err);
    }
}
export async function updateAAddress(data: IAddress) {
    try {
        return await httpClient<any>(`address/${data._id}`, 'PATCH', data);
    } catch (err) {
        return handleErrorResp(err);
    }
}