import { IStandardAPIResponse } from "../../definitions/resp";
import jwt_decode from "jwt-decode";

import environment from 'environment';
const tokenKey = "kbrToken";


export function getToken() {
  let res = localStorage.getItem(tokenKey);
  if (res === null || res === undefined) {
    return "";
  }
  return res;
}

export async function httpClient<T>(
  url: string,
  type: string,
  obj: any = undefined
): Promise<IStandardAPIResponse<T>> {
  try {
    type = type.toUpperCase();
    if (type.toLowerCase() === "get" && obj) {
      const params = Object.keys(obj)
        .map(function (key) {
          if (typeof obj[key] === "object" && obj[key].length !== undefined) {
            let a = obj[key].map((e: any, index: number) => {
              return key + "=" + e;
            });
            return a.join("&");
          } else {
            return key + "=" + obj[key];
          }
        })
        .join("&");
      url += "?" + params;
      obj = undefined;
    }
    const res = await fetch(environment.api.base + "/" + url, {
      method: type.toUpperCase(),
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        token: getToken(),
      },
    });
    return await res.json();
  } catch (error) {
    console.group(`API ${type} Error`);
    console.error(error);
    console.groupEnd();
    throw error;
  }
}

export const setToken = (token: string) => {
  localStorage.setItem(tokenKey, token);
};
export const removeToken = () => {
  localStorage.removeItem(tokenKey);
};


export const handleErrorResp = (err: any): IStandardAPIResponse<any> => {
  console.error(err);
  return {
    success: "ok",
    data: err,
    message: "",
  };
};

export const parseJwt = (tokenParsed?: string) => {
  let token;
  if (!tokenParsed) {
    token = getToken();
  } else {
    token = tokenParsed;
  }

  // Check if the token is non-empty and a string
  if (token && typeof token === "string") {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url?.replace("-", "+")?.replace("_", "/");
      return JSON.parse(window.atob(base64));
    } catch (error) {
      console.error("Error decoding token:", error);
      // Handle the invalid token error here, for example, redirect to login page.
      // For now, returning undefined.
      return undefined;
    }
  } else {
    console.error("Invalid token specified");
    // Handle the invalid token error here, for example, redirect to login page.
    // For now, returning undefined.
    return undefined;
  }
};



export const isTokenExpired = (tokenString: string | null) => {
  return !tokenString;
};


export const findUserId = () => {
  let token: any = localStorage.getItem(tokenKey);
  let userId = ""
  if (token) {
    let decoded: any = jwt_decode(token);
    userId = decoded.id
  }
  return userId
}

export const findCustomUserId = () => {
  let token: any = localStorage.getItem(tokenKey);
  let userId = ""
  if (token) {
    let decoded: any = jwt_decode(token);
    userId = decoded.userId
  }
  return userId
}


export function getName() {
  let token = localStorage.getItem(tokenKey);
  var decoded = jwt_decode(token as string);
  //@ts-ignore
  let { name } = decoded;
  if (name && name != undefined) {
    return name
  }

}