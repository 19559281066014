import { BASE_URL } from 'common/constant';
import environment, { Environment } from './env.base';

const baseApi = BASE_URL
const env = environment(baseApi);

const devEnv: Environment = {
    ...env,
    api: {
        ...env.api
    },
    isDevelopment: true
}

export default devEnv;