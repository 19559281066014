import React from 'react'
import { FaCartPlus } from 'react-icons/fa';
import { Button } from "@mui/material";
import Grid from '@mui/material/Grid';

const NoItem = () => {
    return (
        <Grid container maxWidth="lg">
            <Grid item md={12} xs={12} style={{ justifyContent: "center", textAlign: "center", marginTop: "-50px" }}>
                <>
                    <FaCartPlus style={{ width: "4rem", height: "4rem", color: "#ff9800" }} />
                    <h2>            Your cart is empty
                    </h2>
                    <Button variant="contained" style={{ color: "white" }} href='/'>
                        Browse Products
                    </Button>
                </>
            </Grid>
        </Grid>

    )
}

export default NoItem
