import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { toast } from "react-toastify";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from "react-router-dom";
import TagFacesIcon from '@mui/icons-material/TagFaces';
import FaceIcon from '@mui/icons-material/Face';
import AddressPage from './AddressPage'
import { findUserId, getToken } from '../app/services/UtilServices'
import { findUserDetails, storeFCM } from "app/services/customer/customerAPI";
import OrderDetailsPage from './OrderPage'
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { requestForToken } from "common/firebase";


const ProfilePage = () => {

    const isLoggedIn = useSelector((state: any) => state.auth.isLogedIn);
    console.log("login", isLoggedIn)
    const [showOrder, setShowOrder] = React.useState(false)
    const [showAddress, setShowAddress] = React.useState(false);
    const [isAdressUpdated, setIsAdressUpdated] = React.useState(false)


    const [customer, setCustomer] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        gender: ""
    });
    const [addres, setAddress] = React.useState({
        name: "",
        email: "",
        landmark: "",
        location: "",
        area: "",
        additionalInfo: "",
        mobile: "",
        pin: "",
    });
    let history = useHistory();

    React.useEffect(() => {
        document.title = "KhabarBolo | Profile ";
        const token = getToken()

        if (token) {
            fetchUser();
        }
        else {
            history.push('/login')
        }
    }, [history]);


    const fetchUser = async () => {
        try {
            const result = await findUserDetails(findUserId())
            if (result && result.data[0] != null) {
                setCustomer(result.data[0]);
                setAddress(result.data[0].address[0])
                if (result.data && !result.data[0].fcmDeviceToken) {
                    const fcmToken = await requestForToken()
                    if (fcmToken) {
                        await storeFCM(result.data[0].id, fcmToken)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <React.Fragment>
            {/* {isLoggedIn ? ( */}
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container
                    maxWidth="md" component="main" style={{ marginTop: "10rem" }}
                >
                    <Card style={{ backgroundColor: "#fff3e0", textAlign: "left" }} >
                        <CardContent>
                            <div style={{ textAlign: "center", justifyContent: "center" }}>
                                {customer?.gender === "male" ? <TagFacesIcon color="primary" fontSize="large" /> : <FaceIcon color="primary" fontSize="large" />}
                            </div>
                            <Typography variant="h5" align="center" gutterBottom component="div">
                                Name: {customer?.firstName} {customer?.lastName}
                            </Typography>
                            <Typography variant="h6" align="center" gutterBottom component="div">
                                Email: {customer?.email}
                            </Typography>
                            <Typography variant="h6" align="center" gutterBottom component="div">
                                Mobile:{customer?.phone}
                            </Typography>
                        </CardContent>
                    </Card>


                </Container>
                <Container
                    maxWidth="md" component="main"

                >
                    <Button variant="outlined" onClick={() => setShowOrder(true)}>show orders</Button>
                    <Button variant="outlined" onClick={() => setShowAddress(true)}>show address</Button>
                    {showAddress &&
                        <AddressPage setIsAdressUpdated={setIsAdressUpdated} addressInfo={addres} />
                    }


                </Container>
                {showOrder &&
                    <OrderDetailsPage />
                }

            </Box>
        </React.Fragment>
    );
};

export default ProfilePage;
