import * as React from "react";
import { Card, Box, Button, Grid, TextField, IconButton, Typography, ListItemText, Divider, CardContent, List, ListItem } from "@mui/material";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FaRegEdit } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';
import { MdCancel } from 'react-icons/md';

import { useHistory } from "react-router-dom";
import { findUserId } from "../app/services/UtilServices";
import { addAaddress, updateAAddress } from "app/services/address/addressAPI";

interface Iaddress {
    addressInfo: {
        name: string
        email: string
        landMark: string
        city: string
        area: string
        additionalInfo: string
        phone: string
        postalCode: number
    }
}

const AddressPage: React.FC<Iaddress> = ({ addressInfo, setIsAdressUpdated }) => {


    let history = useHistory();

    const [show, setShow] = React.useState(false);
    const [address, setAddress] = React.useState({
        name: "",
        email: "",
        landMark: "",
        city: "",
        area: "",
        additionalInfo: "",
        phone: "",
        postalCode: "",
    });


    React.useEffect(() => {
        findUpdatedAddress()
    }, [])

    const findUpdatedAddress = () => {
        if (addressInfo && addressInfo.name) {
            setIsAdressUpdated(true)
        }
        else {
            setIsAdressUpdated(false)

        }
    }


    return (
        <React.Fragment>
            <Grid container style={{ margin: "auto", justifyContent: "center" }}>
                <Grid item xs={12} sm={12} md={12}>
                    {addressInfo && (
                        <Card style={{ textAlign: "left" }}>
                            {addressInfo?.name && (
                                <CardContent>
                                    <List>
                                        <ListItem>
                                            <ListItem>
                                                <MdLocationPin style={{ width: "2rem", height: "2rem", color: "#ff9800" }} />
                                                <ListItemText primary="Your default address" />
                                                <IconButton color="primary" onClick={() => setShow(!show)}>
                                                    {show ?
                                                        <MdCancel style={{ width: "1.5rem", height: "1.5rem", color: "#ff9800" }} /> :
                                                        <FaRegEdit style={{ width: "1.5rem", height: "1.5rem", color: "#ff9800" }} />
                                                    }
                                                </IconButton>
                                            </ListItem>
                                        </ListItem>
                                        <Divider />
                                        <ListItem>
                                            <Typography variant="body2">
                                                {addressInfo?.name} / {addressInfo?.phone}
                                            </Typography>
                                        </ListItem>
                                        <Divider />
                                        <ListItem>
                                            <Typography variant="body2">
                                                {addressInfo?.additionalInfo}, {addressInfo?.landMark}, {addressInfo?.area}
                                                {address?.postalCode}
                                            </Typography>
                                        </ListItem>
                                        <Divider />
                                    </List>
                                </CardContent>)}
                        </Card>
                    )}
                    {!addressInfo?.name && (
                        <Card>
                            <CardContent>
                                <Button
                                    style={{ color: "white", textAlign: "center" }}
                                    size="medium"
                                    variant="contained"
                                    onClick={() => setShow(!show)}
                                >
                                    {" "}
                                    Add New address
                                </Button>
                            </CardContent>
                        </Card>
                    )}
                </Grid>
            </Grid>

            {show ? (
                <div style={{ margin: "2rem" }}>
                    <Typography variant="h6" gutterBottom>
                        Shipping address
                    </Typography>
                    <Formik
                        initialValues={{
                            pincode: 742166,
                            area: "Hariharpara",
                            additionalInfo: "",
                            landmark: "",
                            name: "",
                            phone: "",
                            village: ""
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().max(255).required("Name is required"),
                            village: Yup.string().max(25).required("Area is required"),
                            additionalInfo: Yup.string()
                                .max(255),
                            landmark: Yup.string().max(255).required("Land mark is required"),
                            phone: Yup.string()
                                .required("Mobile is required")
                                .matches(/^[0-9]+$/, "Must be only digits")
                                .min(10, "Must be exactly 10 digits")
                                .max(10, "Must be exactly 10 digits"),
                            pincode: Yup.string()
                                .required("Pincode is required")
                                .matches(/^[0-9]+$/, "Must be only digits")
                                .min(6, "Must be exactly 6 digits")
                                .max(6, "Must be exactly 6 digits"),
                        })}
                        onSubmit={async (values: any) => {
                            try {
                                if (!findUserId()) {
                                    history.push('/login')
                                }
                                const data = {
                                    pincode: values.pincode.toString(),
                                    area: values.area,
                                    village: values.village,
                                    additionalInfo: values.additionalInfo,
                                    landMark: values.landmark,
                                    name: values.name,
                                    phone: values.phone.toString(),
                                    city: values.area,
                                    userId: findUserId(),
                                };
                                //@ts-ignore
                                const result = await addAaddress(data);
                                if (result && result.data != null) {
                                    toast.success("Address saved successfully!");
                                    setShow(false)
                                    window.location.reload()
                                } else {
                                    toast.error(result.data.message);
                                }
                            } catch (error) {
                                console.log(error);
                                toast.error(error.message);
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            isValid
                        }) => (
                            <div>
                                <Form>
                                    <Box
                                        sx={{
                                            marginTop: 1,
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Box sx={{ mt: 1 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        id="name"
                                                        name="name"
                                                        label="Receiver's Full Name"
                                                        fullWidth
                                                        autoComplete="name"
                                                        variant="standard"
                                                        value={values.name}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.name && errors.name)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="phone"
                                                        name="phone"
                                                        label="Mobile (We will call during delivery)"
                                                        fullWidth
                                                        type="number"
                                                        variant="standard"
                                                        value={values.phone}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.phone && errors.phone)}
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        id="landMark"
                                                        name="landmark"
                                                        label="Nearby School, Office, Shop"
                                                        fullWidth
                                                        autoComplete="shipping address-line1"
                                                        variant="standard"
                                                        value={values.landmark}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(touched.landmark && errors.landmark)}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        id="additionalInfo"
                                                        name="additionalInfo"
                                                        label="AdditionalInfo, Instruction"
                                                        fullWidth
                                                        autoComplete="additionalInfo"
                                                        value={values.additionalInfo}
                                                        variant="standard"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(
                                                            touched.additionalInfo && errors.additionalInfo
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        id="village"
                                                        name="village"
                                                        label="Village, Para, Moholla *"
                                                        fullWidth
                                                        autoComplete="village"
                                                        value={values.village}
                                                        variant="standard"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        error={Boolean(
                                                            touched.village && errors.village
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        required
                                                        id="area"
                                                        name="area"
                                                        label="Area"
                                                        fullWidth
                                                        autoComplete="area"
                                                        variant="standard"
                                                        value={values.area}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.area && errors.area)}
                                                        aria-readonly={true}

                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        required
                                                        id="pincode"
                                                        name="pincode"
                                                        label="Postal code"
                                                        fullWidth
                                                        autoComplete="pincode"
                                                        variant="standard"
                                                        type="number"
                                                        value={values.pincode}
                                                        onBlur={handleBlur}
                                                        error={Boolean(touched.pincode && errors.pincode)}
                                                        aria-readonly={true}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    textAlign: "center",
                                                    padding: "1rem",
                                                }}
                                            >
                                                <Button
                                                    disabled={!isValid || isSubmitting}
                                                    type="submit"
                                                    style={{ color: "white" }}
                                                    variant="contained"
                                                >
                                                    Save address
                                                </Button>
                                            </div>
                                        </Box>
                                    </Box>
                                </Form>
                            </div>
                        )}
                    </Formik>
                </div>
            ) : null}
        </React.Fragment>
    );
};

export default AddressPage;
