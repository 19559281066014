import * as React from 'react';
import { Paper, Typography, Grid, Box } from '@mui/material';

interface RestaurantFeaturedProps {
    shopInfo: {
        name: string;
        photoUrl: string;
        ownerName: string;
        ownerEmail: string;
        address: string;
    }
}

export default function RestaurantFeatured(props: RestaurantFeaturedProps) {
    const { shopInfo } = props;
    return (
        <Paper
            sx={{
                position: 'relative',
                backgroundColor: 'grey.800',
                color: '#fff',
                mb: 4,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${shopInfo?.photoUrl})`,
                borderRadius: "15px"
            }}
        >
            {<img style={{ display: 'none' }} src={shopInfo.photoUrl} alt={shopInfo.name} />}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.3)',
                    borderRadius: "15px"
                }}
            />
            <Grid container>
                <Grid item md={6}>
                    <Box
                        sx={{
                            position: 'relative',
                            p: { xs: 3, md: 6 },
                            pr: { md: 0 },
                        }}
                    >
                        <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                            {shopInfo.name}
                        </Typography>
                        <Typography variant="h5" color="inherit" paragraph>
                            Street Food, Sandwich, North Indian, Fast Food

                        </Typography>



                        <Typography variant="h5" color="primary" paragraph>
                            Open now
                        </Typography>
                        <Typography>
                            Timings: Everyday 11am to 9pm

                        </Typography>
                        <Typography >
                            {shopInfo.address}
                        </Typography>
                        <Typography  >
                            Contact: <span style={{ color: '#f58320' }}>
                                info.jenie@gmail.com
                            </span>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
}