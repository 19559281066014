// A mock function to mimic making an async request for data
import { handleErrorResp, httpClient } from "../UtilServices";

export async function login(email: string, password: string) {
  try {
    return await httpClient<any>(`user/login`, 'POST', { email, password });
  } catch (err) {
    return handleErrorResp(err);
  }
}

export async function findUserDetails(userId: string) {
  try {
    return await httpClient<any>(`user/${userId}`, 'GET');
  } catch (err) {
    return handleErrorResp(err);
  }
}



export async function userRegister(user: any) {
  try {
    return await httpClient<any>(`register-user`, 'PUT', user);
  } catch (err) {
    return handleErrorResp(err);
  }
}

export async function userLogin(email: string, password: string) {
  try {
    return await httpClient<any>(`auth-user`, 'POST', { email, password });
  } catch (err) {
    return handleErrorResp(err);
  }
}

export async function socialLogin(user: any) {
  try {
    return await httpClient<any>(`auth-user-social`, 'POST', user);
  } catch (err) {
    return handleErrorResp(err);
  }
}

export async function storeFCM(userId: string, fcmDeviceToken: string) {
  try {
    return await httpClient<any>(`fcm/${userId}`, 'PATCH', { fcmDeviceToken: fcmDeviceToken });
  } catch (err) {
    return handleErrorResp(err);
  }
}