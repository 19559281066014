import React from "react";
import "./Footer.css";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Copyright from "./Copyright";
//import { MessengerChat } from "react-messenger-chat-plugin";

const FooterSection = () => {



  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
      }}
      style={{ backgroundColor: "black", color: "white", minHeight: "35vh" }}
    >
      <Container maxWidth="md" style={{ marginTop: "5rem" }}>
        <Grid container justifyContent="space-evenly">
          <Grid item xs={12} sm={4} >
            <img
              src="/static/fulllogo.png"
              data-rimg-scale="1"
              height={250}
              width={310}
              alt="logo"
              style={{ marginTop: "-50px" }}
            />
            <div style={{ marginTop: "-20px", textAlign: "center" }}>
              <Typography variant="caption" display="block" gutterBottom>Designed & developed by
                <Link target="_blank" href="https://www.facebook.com/unsuredev"> Jamal
                </Link>
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" color="primary" gutterBottom>
              Features
            </Typography>
            <ul>
              <li>
                <Link target="_blank" href="/aboutus">
                  About Us
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/aboutus">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link target="_blank" href="https://www.facebook.com/jenie">
                  Read Our Post
                </Link>
              </li>
              <li>
                <Link target="_blank" href="https://tawk.to/jenie">
                  Chat with Us
                </Link>
              </li>
            </ul>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography variant="h6" color="primary" gutterBottom>
              Legal & Support
            </Typography>
            <ul>
              <li>
                <Link target="_blank" href="/terms-condition" >
                  Return Policy
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/terms-condition">
                  Terms Of Use
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/terms-condition">
                  Cancellation & Returns
                </Link>
              </li>
              <li>
                <Link target="_blank" href="/f-a-q">
                  FAQ
                </Link>
              </li>

            </ul>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography variant="h6" color="primary" gutterBottom>
              Social
            </Typography>
            <ul>
              <li>
                <Link target="_blank" href="https://www.facebook.com/jenie">
                  Facebook
                </Link>
              </li>

              <li>
                <Link target="_blank" href=" https://www.instagram.com/jenie">
                  Instagram
                </Link>
              </li>
              <li>Twitter</li>
            </ul>
          </Grid>
        </Grid>
        <Copyright />
      </Container>
      {/* <MessengerChat
        pageId="104337575635403"
        language="en_US"
        themeColor={"#000000"}
        loggedInGreeting="loggedInGreeting"
        loggedOutGreeting="loggedOutGreeting"
        greetingDialogDisplay={"show"}
        debugMode={true}
        onMessengerShow={() => {
          console.log("onMessengerShow");
        }}
        onMessengerHide={() => {
          console.log("onMessengerHide");
        }}
        onMessengerDialogShow={() => {
          console.log("onMessengerDialogShow");
        }}
        onMessengerDialogHide={() => {
          console.log("onMessengerDialogHide");
        }}
        onMessengerMounted={() => {
          console.log("onMessengerMounted");
        }}
        onMessengerLoad={() => {
          console.log("onMessengerLoad");
        }}
      /> */}
    </Box >
  );
};

export default FooterSection;
