import * as React from 'react';
import { Grid, Box, Container } from "@mui/material";
import BannerContent from 'components/Banner/BannerContents';
import moment from 'moment';


const NotificationArea = ({ message }) => {
    const { imageUrl, title, description } = message
    const [showBanner, setShowBanner] = React.useState(false);

    // React.useEffect(() => {
    //  const openingHour = 0; // 12 PM
    //  const closingHour = 11; // 7 PM
    // const checkOpeningHours = () => {
    //     const now = moment();
    //     const currentHour = now.hour();
    //     if (currentHour >= openingHour && currentHour < closingHour) {
    //         setShowBanner(false);
    //     } else {
    //         setShowBanner(true);
    //     }
    // };

    // checkOpeningHours();

    // // Check the opening hours every minute to update the UI
    // const interval = setInterval(checkOpeningHours, 60000);

    // return () => {
    //     clearInterval(interval);
    // };
    //}, []);

    return (
        <Container style={{ marginBottom: "10rem", marginTop: "5rem" }}>
            {showBanner ? (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} style={{ backgroundColor: "antiquewhite" }}>
                        <BannerContent firstImageSrc="https://as2.ftcdn.net/v2/jpg/02/45/70/49/1000_F_245704908_fhpQFd9b3WbinizjzcHmyyQp3zvuWJU2.jpg"
                            bannerTitle="Jenie is closed" bannerDescription="Opens everyday 9am!"
                            lastImageSrc="https://media.istockphoto.com/id/1281403210/vector/cute-apple-fruit-character-sleep-cartoon-vector.jpg?s=612x612&w=0&k=20&c=1m4NF4JKzHjVIyns8WcdeRGbaxEgzU8BIB_pBgPwlic=" />
                    </Grid>
                </Box>
            ) : (
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} style={{ backgroundColor: "antiquewhite" }}>
                        <BannerContent firstImageSrc={imageUrl} bannerTitle={title} bannerDescription={description} lastImageSrc={imageUrl} />
                    </Grid>
                </Box>)
            }
        </Container >

    );
}

export default NotificationArea
