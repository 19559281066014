import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
    Grid,
    Paper,
    Box,
    Container,
    Card,
    Button,
    Link,
    Typography,
    ListItemText,
    Divider,
    List,
    ListItem,
} from "@mui/material";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
    updateQuantity,
    removeItem,
    calculateTotalAmount,
} from "store/cartSlice";
import CartItem from "components/CartItems/CartItem";
import AddressForm from "./AddressPage";
import { RxCrossCircled } from "react-icons/rx";

import MakePayment from "./Payment";
import NoItem from "components/Miscellaneous/NoItem";
import CouponSection from "components/Coupon/Coupon";
import { addOrder } from "app/services/order/orderAPI";
import { IOrder } from "app/order";
import { findUserId } from "app/services/UtilServices";
import { SERVICE_CHARGE, FIXED_DELIVERY_FEE } from "common/constant";
import OrderConfirm from "components/OrderConfirmation/OrderConfirm";
import { findUserDetails, storeFCM } from "app/services/customer/customerAPI";
import Spinner from "components/Miscellaneous/Spinner";
import { requestForToken } from "common/firebase";

interface ICartItem {
    id: string;
    name: string;
    unit: string;
    amount: number;
    quantity: number;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const CartPage: React.FC<ICartItem> = () => {
    const [deliverTips, setDeliveryTips] = React.useState(0);
    const [activeStep, setActiveStep] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [order, setOrder] = React.useState(0);
    const [paymentObj, setPaymentObj] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [addressData, setAddressData] = React.useState({});
    const [useAddress, setUseAddress] = React.useState(false);

    const [isAdressUpdated, setIsAdressUpdated] = React.useState(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const dispatch = useDispatch();
    const cartItems = useSelector((state: any) => state.cart.items);

    useEffect(() => {
        document.title = "KhabarBolo | Checkout ";
        const newTotalAmount = cartItems.reduce(
            (total: number, item: any) => total + item.amount * item.quantity,
            0
        );
        dispatch(calculateTotalAmount(newTotalAmount));
        dispatch(calculateTotalAmount());
        setActiveStep(0);
    }, [cartItems, dispatch]);

    const handleQuantityChange = (id: string, quantity: number) => {
        dispatch(updateQuantity({ id: id, quantity }));
        dispatch(calculateTotalAmount());
        if (quantity !== 0 && quantity < 7) {
            toast.success(`Item quantity is changed to ${quantity}`);
        }
        if (quantity < 1) {
            toast.warn(`Item removed from the cart`);
        }
    };

    const handleRemoveFromCart = (id: string) => {
        dispatch(removeItem({ productId: id }));
        dispatch(calculateTotalAmount());
        toast.warn(`Item removed from the cart`);
    };
    const totalAmount = useSelector((state: any) => state.cart.totalAmount);
    const totalItems = cartItems.reduce(
        (total: number, item: any) => total + item.quantity,
        0
    );

    const handleTip = (amount: number) => {
        setDeliveryTips(deliverTips + amount);
        toast.success(`Your tip ${amount} rupess for delivery partner is added`);
    };

    const RemoveTip = () => {
        setDeliveryTips(0);
        toast.warn(`Your have removed partner delivery Tip`);
    };

    const RemoveCoupon = () => {
        setDiscount(0);
        toast.warn(`Your have removed offer coupon!`);
    };

    const createOrder = async () => {
        try {
            setLoading(true);
            const orderPayload: IOrder = createOrderPayload(cartItems);
            const result = await addOrder(orderPayload);
            console.log("order data", result);
            setLoading(false);
            if (result.data) {
                setOrder(result.data);
                handleNext();
            }
        } catch (error) {
            console.log(error);
        }
    };

    function createOrderPayload(cartData: CartItem[]): IOrder {
        if (!findUserId() && cartData.length > 0) {
            throw new Error("Could not verify your deatils");
        }
        const orderPayload: IOrder = {
            userId: findUserId(),
            shopId: cartData[0]?.shopId,
            products: [],
            paymentMethod: "upi",
            paymentStatus: "pending",
            serviceCharge: SERVICE_CHARGE,
            effectiveDeliveryCharge: FIXED_DELIVERY_FEE,
            deliveryCharge: FIXED_DELIVERY_FEE,
            status: "1",
            orderTime: new Date(),
        };

        cartData.forEach((cartItem) => {
            const product = cartItem.product;
            const item = {
                productId: product.id,
                totalPrice: product.sellingPrice,
                quantity: cartItem.quantity,
                priceUnit: product.unitLabel,
                unitLabel: product.unitLabel,
                buyingPriceRate: product.sellingPrice,
                title: product.title,
            };
            orderPayload.products.push(item);
        });
        return orderPayload;
    }

    React.useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = async () => {
        try {
            if (!findUserId()) {
                return toast.warn("Login to continue");
            }
            const result = await findUserDetails(findUserId());
            if (result && result.data[0].address[0] != null) {
                setAddressData(result.data[0].address[0]);
                setUseAddress(false);
                if (result.data && !result.data[0].fcmDeviceToken) {
                    const fcmToken = await requestForToken()
                    if (fcmToken) {
                        await storeFCM(result.data[0].id, fcmToken)
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const ValidTotal = () => {
        let totalAmt =
            totalAmount +
            SERVICE_CHARGE +
            FIXED_DELIVERY_FEE +
            deliverTips -
            discount;
        if (totalAmt <= 0) {
            totalAmt = 1;
        }
        return totalAmt;
    };

    return (
        <>
            <Container style={{ marginTop: "220px", marginBottom: "300px" }}>
                {activeStep === 5 ? (
                    <OrderConfirm paymentObj={paymentObj} />
                ) : (
                    <>
                        {cartItems.length === 0 ? (
                            <NoItem />
                        ) : (
                            <Box>
                                <Grid
                                    container
                                    spacing={2}
                                    style={{ justifyContent: "center" }}
                                >
                                    {activeStep <= 2 && (
                                        <Grid item xs={12} md={8}>
                                            <div>
                                                <h3>
                                                    Cart ({totalItems} Items){" "}
                                                    <span style={{ color: "green" }}>
                                                        ₹{totalItems * 6} saved on this order{" "}
                                                    </span>{" "}
                                                </h3>
                                            </div>
                                            {activeStep <= 2 && (
                                                <>
                                                    <div style={{ overflow: "auto" }}>
                                                        <TableContainer component={Paper}>
                                                            <Table
                                                                stickyHeader
                                                                sx={{ minWidth: 650 }}
                                                                aria-label="Cart Items table"
                                                            >
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Item photo</TableCell>
                                                                        <TableCell>Item</TableCell>
                                                                        <TableCell>Unit</TableCell>
                                                                        <TableCell>Amount</TableCell>
                                                                        <TableCell>&nbsp;&nbsp; Action</TableCell>
                                                                    </TableRow>
                                                                </TableHead>

                                                                <TableBody>
                                                                    {cartItems.map((item: any) => (
                                                                        <CartItem
                                                                            key={item.product.id}
                                                                            item={item.product}
                                                                            quantity={item.quantity}
                                                                            onQuantityChange={handleQuantityChange}
                                                                            onRemoveFromCart={handleRemoveFromCart}
                                                                        />
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                    <Item style={{ marginTop: "1rem" }}>
                                                        <Typography variant="h6" gutterBottom>
                                                            Delivery Partner Tip
                                                        </Typography>
                                                        <p>
                                                            {" "}
                                                            The entire amount will be sent to your delivery
                                                            partner
                                                        </p>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                textAlign: "center",
                                                                padding: "5px",
                                                                margin: "5px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Button
                                                                onClick={() => handleTip(5)}
                                                                style={{ borderRadius: "10px", color: "white" }}
                                                                size="small"
                                                                variant="contained"
                                                                startIcon={<VolunteerActivismIcon />}
                                                            >
                                                                ₹5
                                                            </Button>
                                                            &nbsp;
                                                            <Button
                                                                onClick={() => handleTip(10)}
                                                                style={{ borderRadius: "10px", color: "white" }}
                                                                size="medium"
                                                                variant="contained"
                                                                startIcon={<VolunteerActivismIcon />}
                                                            >
                                                                ₹10
                                                            </Button>
                                                            &nbsp;
                                                            <Button
                                                                onClick={() => handleTip(20)}
                                                                style={{ borderRadius: "10px", color: "white" }}
                                                                size="medium"
                                                                variant="contained"
                                                                startIcon={<VolunteerActivismIcon />}
                                                            >
                                                                ₹20
                                                            </Button>
                                                        </div>
                                                    </Item>
                                                </>
                                            )}
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={4} style={{ textAlign: "left" }}>
                                        <Item
                                            style={{
                                                justifyContent: "space-between",
                                                display: "flex",
                                            }}
                                        >
                                            <div style={{ display: "flex" }}>
                                                <CardGiftcardOutlinedIcon
                                                    fontSize="large"
                                                    style={{ color: "#28b77b" }}
                                                />
                                                <Typography variant="h6" gutterBottom>
                                                    &nbsp; Avail Offers / Coupons
                                                </Typography>
                                            </div>

                                            <div>
                                                <CouponSection
                                                    setDiscount={setDiscount}
                                                    discount={discount}
                                                />
                                            </div>
                                        </Item>

                                        <Item>
                                            <List sx={{ width: "100%" }}>
                                                <ListItem>
                                                    <ListItemText primary="Item Amount" />
                                                    <ListItemText style={{ textAlign: "end" }}>
                                                        <b>&#8377;{totalAmount}</b>
                                                    </ListItemText>
                                                </ListItem>
                                                <Divider />

                                                <ListItem>
                                                    <ListItemText
                                                        primary="Handling Fee"
                                                        secondary="Packing & Small cart fee"
                                                    />
                                                    <ListItemText style={{ textAlign: "end" }}>
                                                        <p>
                                                            <s>&#8377;15</s>&nbsp;&nbsp;
                                                            <span style={{ color: "346ca7b" }}>
                                                                &#8377;{SERVICE_CHARGE}
                                                            </span>
                                                        </p>
                                                    </ListItemText>
                                                </ListItem>
                                                <Divider />
                                                <ListItem>
                                                    <ListItemText primary="Delivery Fee" secondary="" />
                                                    <ListItemText style={{ textAlign: "end" }}>
                                                        <p>
                                                            <s>&#8377;20</s>&nbsp;
                                                            <span style={{ color: "346ca7b" }}>
                                                                &#8377;{FIXED_DELIVERY_FEE}
                                                            </span>
                                                        </p>
                                                    </ListItemText>
                                                </ListItem>
                                                <Divider />
                                                {deliverTips > 0 && (
                                                    <>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary="Delivery Partner Tip"
                                                                secondary="Thank you for your love!"
                                                            />
                                                            <ListItemText style={{ textAlign: "end" }}>
                                                                <RxCrossCircled
                                                                    color="red"
                                                                    onClick={RemoveTip}
                                                                />
                                                            </ListItemText>
                                                            <ListItemText style={{ textAlign: "end" }}>
                                                                <p>&#8377;{deliverTips}</p>
                                                            </ListItemText>
                                                        </ListItem>
                                                        <Divider />
                                                    </>
                                                )}
                                                {discount > 0 && (
                                                    <>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary="Coupon discount"
                                                                secondary="Total discount"
                                                            />
                                                            <ListItemText style={{ textAlign: "end" }}>
                                                                <RxCrossCircled
                                                                    color="red"
                                                                    onClick={RemoveCoupon}
                                                                />
                                                            </ListItemText>
                                                            <ListItemText style={{ textAlign: "end" }}>
                                                                <p style={{ color: "346ca7b" }}>
                                                                    &#8377;{discount}
                                                                </p>
                                                            </ListItemText>
                                                        </ListItem>
                                                        <Divider />
                                                    </>
                                                )}

                                                <ListItem>
                                                    <ListItemText primary="To Pay" />
                                                    <ListItemText style={{ textAlign: "end" }}>
                                                        <h3>&#8377; {ValidTotal()}</h3>
                                                    </ListItemText>
                                                </ListItem>
                                                {activeStep === 0 ? (
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            color: "white",
                                                            textAlign: "center",
                                                            borderRadius: "10px",
                                                        }}
                                                        size="large"
                                                        onClick={() => handleNext()}
                                                    >
                                                        Procced to buy
                                                    </Button>
                                                ) : null}
                                            </List>
                                        </Item>
                                        {activeStep === 1 ||
                                            activeStep === 2 ||
                                            activeStep === 3 ? (
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    justifyContent: "center",
                                                    margin: "auto",
                                                }}
                                            >
                                                <AddressForm
                                                    setIsAdressUpdated={setIsAdressUpdated}
                                                    addressInfo={addressData}
                                                />
                                                {activeStep === 1 && (
                                                    <Button
                                                        variant="contained"
                                                        style={{
                                                            color: "white",
                                                            textAlign: "center",
                                                            borderRadius: "10px",
                                                        }}
                                                        size="large"
                                                        onClick={() => handleNext()}
                                                        disabled={!isAdressUpdated}
                                                    >
                                                        use this address
                                                    </Button>
                                                )}

                                                {activeStep === 2 ? (
                                                    <>
                                                        {loading ? (
                                                            <Spinner />
                                                        ) : (
                                                            <Button
                                                                variant="contained"
                                                                style={{
                                                                    color: "white",
                                                                    textAlign: "center",
                                                                    borderRadius: "10px",
                                                                }}
                                                                size="large"
                                                                onClick={() => createOrder()}
                                                            >
                                                                Continue to payment
                                                            </Button>
                                                        )}
                                                    </>
                                                ) : null}

                                                {activeStep === 3 ? (
                                                    <>
                                                        <MakePayment
                                                            order={order}
                                                            paymentdata={paymentObj}
                                                            setPaymentObj={setPaymentObj}
                                                            setActiveStep={setActiveStep}
                                                        />
                                                        <Card>
                                                            <div style={{ margin: "5px", padding: "10px" }}>
                                                                <p>
                                                                    <span style={{ color: "red" }}>Note:</span> No
                                                                    refund for cancellation made after order
                                                                    accepted by restaurant/shop. Avoid
                                                                    cancellation as it leads to food wastage.
                                                                </p>
                                                                <Link target="_blank" href="/terms-condition">
                                                                    Read cancellation policy
                                                                </Link>
                                                            </div>
                                                        </Card>
                                                    </>
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </>
                )}
            </Container>
        </>
    );
};

export default CartPage;
