import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Card, CardMedia, Container, Grid } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import './productStyle.css';
import VegNonVeg from '../Product/VegNonVeg';
import { addItem, updateQuantity, removeItem, calculateTotalAmount } from '../../store/cartSlice';


const Product = (props: any) => {
    const dispatch = useDispatch();

    const { products, shopId } = props;

    const cartItems = useSelector((state: any) => state.cart.items);

    const handleAddToCart = (product: any) => {
        const existingItem = cartItems.find((item: any) => item.product.id === product.id);
        if (existingItem) {
            dispatch(updateQuantity({ id: product.id, quantity: existingItem.quantity + 1 }));
            dispatch(calculateTotalAmount());
        } else {
            dispatch(addItem({ product: product, shopId: shopId }));
            dispatch(calculateTotalAmount());

        }
        toast.success(`${product.title} added to cart`);
    };

    const handleRemoveFromCart = (product: any) => {
        const existingItem = cartItems.find((item: any) => item.product.id === product.id);
        if (existingItem && existingItem.quantity > 1) {
            dispatch(updateQuantity({ id: product.id, quantity: existingItem.quantity - 1 }));
            dispatch(calculateTotalAmount());

        } else {
            dispatch(removeItem({ id: product.id }));
            dispatch(calculateTotalAmount());

        }
        toast.warn(`${product.title} removed from cart`);
    };


    return (
        <Container>
            <Grid container>
                {products.map((product: any) => (
                    <Grid item xs={12} sm={6} md={4} key={product.id} >
                        <div className="pcards-container" key={product.id}>
                            <div className="pcard">
                                <div className="pcard-media">
                                    <Card>
                                        <CardMedia
                                            sx={{ height: 220 }}
                                            image={product.imageUrl}
                                            title={product.title}
                                        />
                                    </Card>
                                    <div className="discount">₹{product.mrp - product.sellingPrice} OFF</div>
                                    <div className="pro-discount">Free Delivery</div>
                                    <div className="delivery-time">{product.deliveryTime} mins</div>
                                </div>
                                <div className="pcard-description">
                                    <div className="about-place">
                                        <div className="place">
                                            <div>
                                                <h4 className="place-name">
                                                    {product.title} <VegNonVeg type={product.isVeg} />
                                                </h4>
                                            </div>
                                            <p className="place-speciality">{product.description}&nbsp;Serving size:{product.unitLabel}</p>
                                        </div>
                                        <div className="place-review">
                                            <h3 className="pricing">₹{product.sellingPrice}</h3>
                                            <div className="quantity-control">
                                                {cartItems.find((item: any) => item.product.id === product.id)?.quantity > 0 && (
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => handleRemoveFromCart(product)}
                                                        style={{ borderRadius: "8px", color: "white", backgroundColor: "#f58320", }}
                                                    >
                                                        <RemoveIcon fontSize='small' />
                                                    </IconButton>
                                                )}
                                                {cartItems.find((item: any) => item.product.id === product.id)?.quantity > 0 && (
                                                    <div className="quantity">
                                                        {cartItems.find((item: any) => item.product.id === product.id)?.quantity || 0}
                                                    </div>
                                                )}
                                                <IconButton
                                                    size='small'
                                                    onClick={() => handleAddToCart(product)}
                                                    style={{ borderRadius: "8px", color: "white", backgroundColor: "#f58320" }}
                                                >
                                                    <AddIcon fontSize='small' />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default Product;
