import React, { ComponentType, FunctionComponent } from 'react';
import { Route, Redirect } from "react-router-dom";
import { getToken, isTokenExpired } from '../app/services/UtilServices';

interface IAuthenticatedRoute {
    component: ComponentType<any> | FunctionComponent<any>;
    path: string;
    exact: boolean;
}

const token = getToken()


export const AuthenticatedRoute: React.FC<IAuthenticatedRoute> = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props: any) =>
            isTokenExpired(token) ? (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                        search: `?redirect_to=${props.location.pathname}${props.location.search}${props.location.hash}`,
                    }}
                />
            ) : (
                <Component {...props} />
            )
        }
    />
);
