import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import {
  CssBaseline,
  Divider,
  Link,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import moment from "moment";

import OrderSuccess from "./OrderSuccess";

interface IPaymentResult {
  paymentObj: {
    orderId: string;
    paymentMethod: string;
    paymentStatus: string;
    invoiceNo: string;
    rzpPaymentId: string;
    createdAt: string;
    amount: number;
  };
}

const OrderConfirm: React.FC<IPaymentResult> = ({ paymentObj }) => {

  React.useEffect(() => {
    window.scrollTo(0, 0)
    localStorage.setItem('cartItems', JSON.stringify([]));
  }, [])



  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          style={{ marginTop: "15rem", marginBottom: "15rem" }}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Card>
                <CardContent>
                  {/* <Typography variant="caption" display="block" gutterBottom textAlign="center" color="red">
                    Kindly do not press back button or refresh
                  </Typography> */}

                  <Typography variant="h6" gutterBottom textAlign="center">
                    Your transaction was successful !
                  </Typography>
                  <br></br>

                  <OrderSuccess />
                  <div style={{ marginTop: "3rem" }}>
                    <Typography variant="h6" gutterBottom>
                      Payment Details:
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6}>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          Order Id:
                        </Typography>

                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          Account:
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          Payment status:
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          PaymentId:
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          Transaction Date:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          {paymentObj.orderId}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          &#8377;{paymentObj.amount}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          {paymentObj.paymentStatus}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          {paymentObj.rzpPaymentId}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                          {moment(paymentObj.createdAt).format("LLLL")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <Divider />
                </CardContent>
                <CardActions>
                  <Link target="_self" href="/profile">
                    Check order status
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default OrderConfirm;
