
import React from 'react';
import { Grid, Typography } from '@mui/material';
import './BannerContent.css';

interface IContent {
  firstImageSrc: string;
  bannerTitle: string;
  bannerDescription: string;
  lastImageSrc: string
}
const BannerContent: React.FC<IContent> = ({ firstImageSrc, bannerTitle, bannerDescription, lastImageSrc }) => {

  return (
    <Grid container spacing={2} className="festival-container">
      <Grid item xs={4} md={4} sm={4} style={{ justifyContent: "center", display: "flex" }}>
        <img className='NoBackground'
          src={firstImageSrc} alt='first banner image'
        />
      </Grid>
      <Grid item xs={4} md={4} sm={4} className="festival-text">
        <div style={{ justifyContent: "center", textAlign: "center", marginTop: "3rem" }}>
          <Typography variant="h6" gutterBottom >
            {bannerTitle}
          </Typography>
          <p>{bannerDescription}</p>
        </div>

      </Grid>
      <Grid item xs={4} sm={4} md={4} style={{ justifyContent: "center", display: "flex" }}>
        <img className='NoBackground'
          src={lastImageSrc}
          alt='second banner image'
        />
      </Grid>
    </Grid>
  );
};

export default BannerContent;
