import * as React from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { toast } from 'react-toastify';
import Spinner from 'components/Miscellaneous/Spinner';

interface CouponProps {
    setDiscount: (discount: number) => void;
    discount: number;
}


const CouponSection: React.FC<CouponProps> = ({ setDiscount, discount }) => {

    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleApply = () => {
        setLoading(true);
        // Simulating an API call with setTimeout
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
            const appliedDiscount = 1; // Change this value according to your needs
            setDiscount(appliedDiscount);
            toast.success('Your coupon applied successfully. Check order details.');
        }, 3000);
    };

    return (
        <>
            <div >

                <Button variant="outlined" onClick={handleClickOpen} size='small'>
                    apply
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    {loading ?
                        <Spinner />
                        :
                        <div >
                            <DialogTitle>Apply Coupon</DialogTitle>
                            <DialogContent>

                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="coupon"
                                    label="Enter Coupon Code"
                                    type="text"
                                    variant="outlined"
                                    name='coupon'
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                                <Button variant="contained" style={{ color: "white" }} onClick={handleApply}>Apply</Button>
                            </DialogActions>
                        </div>
                    }
                </Dialog>
            </div>
        </>

    )
}
export default CouponSection
