import * as React from 'react';
import { Typography, Grid, Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import VegNonVeg from 'components/Product/VegNonVeg';
import Chip from '@mui/material/Chip';


interface FeaturedPostProps {
    product: {
        id: string
        description: string;
        imageUrl: string;
        title: string;
        sellingPrice: number
        isVeg: boolean
        unitLabel: string
    };
}

export default function FeaturedPost(props: FeaturedPostProps) {
    const { product } = props;
    return (
        <Grid item xs={12} md={6}>
            <CardActionArea component="a" href="#">
                <Card sx={{ display: 'flex' }}>
                    <CardContent sx={{ flex: 1 }}>
                        <h2>
                            {product.title}  <VegNonVeg type={product.isVeg} />
                        </h2>
                        <Chip label="Best Seller" color="primary" variant="filled" style={{ color: "white" }} />

                        <Typography variant="subtitle1" paragraph>
                            {product.description}
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            &#x20b9;{product.sellingPrice}
                        </Typography>
                        <Typography variant="subtitle1" paragraph>
                            Serving size: {product.unitLabel}
                        </Typography>
                    </CardContent>
                    <CardMedia
                        component="img"
                        sx={{ width: 320, display: { xs: 'none', sm: 'block' } }}
                        image={product.imageUrl}
                        alt={product.title}
                    />
                </Card>
            </CardActionArea>
        </Grid>
    );
}