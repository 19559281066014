export const BASE_URL =
  process.env.BASE_URL ||
  "https://vni8qjt0l2.execute-api.ap-south-1.amazonaws.com/dev";
//"http://localhost:3001"

export const GCLIENT_ID =
  process.env.REACT_APP_GCLIENT_ID ||
  "792870786224-069oqgh9lr7gfd5s8v2eapqea57g9f9t.apps.googleusercontent.com";

export const REDIRECT_URL =
  process.env.REACT_APP_REDIRECT_URL ||
  "";

export const DEFAULT_CLIENT_ID =
  process.env.REACT_APP_DEFAULT_CLIENT_ID ||
  "6e8fd70a-78c2-44f4-a85f-014d53de9cb8";

export const RAZOR_PAY_KEY =
  process.env.REACT_APP_RAZOR_PAY_KEY ||
  "rzp_live_z5lqGyXje3WJSW"
//"rzp_test_tnpNVrJjfVw71n"


export const FIXED_DELIVERY_FEE = 0;
export const SERVICE_CHARGE = 0;

export const ORDER_STATUS: any = {
  "0": "Order Initiated",
  "1": "Order Placed",
  "-1": "Order Failed",
  "2": "Order Delivered",
  "-2": "Refused by Customer",
  "-4": "Cancelled by Admin",
  "5": "Order Approved",
  "-5": "Order Rejected",
  "6": "Order in progress",
  "7": "Picked up from seller",
};