import React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import { IconButton, TableCell, TableRow } from "@mui/material";

interface CartItemProps {
    item: {
        id: string;
        title: string;
        unitLabel: string;
        sellingPrice: number;
        imageUrl: string
    };
    quantity: number;
    onQuantityChange: (id: string, quantity: number) => void;
    onRemoveFromCart: (id: string) => void;
}

const CartItem: React.FC<CartItemProps> = ({ item, quantity, onQuantityChange, onRemoveFromCart }) => {
    return (
        <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
                <Avatar sx={{ width: 56, height: 56 }} style={{ borderRadius: 0 }}
                    alt={item.title} src={item.imageUrl} />
            </TableCell>

            <TableCell component="th" scope="row">
                {item.title}
            </TableCell>
            <TableCell >{item?.unitLabel?.toUpperCase()}</TableCell>
            <TableCell >&#8377;{item.sellingPrice}</TableCell>
            <TableCell >
                <div className="quantity-control">
                    <IconButton
                        size='small'
                        onClick={() => onQuantityChange(item.id, quantity - 1)}
                        style={{ borderRadius: "8px", color: "white", backgroundColor: "#f58320", }}
                    >
                        <RemoveIcon fontSize='small' />
                    </IconButton>

                    <span className="quantity">{quantity}</span>
                    <IconButton
                        size='small'
                        onClick={() => onQuantityChange(item.id, quantity + 1)}
                        style={{ borderRadius: "8px", color: "white", backgroundColor: "#f58320" }}
                    >
                        <AddIcon fontSize='small' />
                    </IconButton>

                </div>
            </TableCell>
        </TableRow>
    );
};

export default CartItem;
